
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {
    Badge,
    Button,
    Col, FormFeedback,
    FormGroup,
    FormText, Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner
} from "reactstrap";
import FormTextField from "../../../common/formComponents/FormTextField";
import FormDropdown from "../../../common/formComponents/FormDropdown";
import * as yup from "yup";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {getAllInquiry, getInquiryStatus, InquiryRegister} from "../../../services/InquiryService";

const InquiryEditModel = (props) =>{
    const { isOpen, toggleModal, clickedEditRowData, isNew,fetchPayeeRegisterData } = props;

    const { t } = useTranslation();
    const [loading, setLoading] = useState(false)
    const [isInquiryStatus, setInquiryStatus] = useState([])
    const backOfficeUserDTO = useSelector((state) => state.auth.data.backOfficeUserDTO);

    useEffect(()=>{
        fetchInquiryData()
    },[])

    const fetchInquiryData = async () =>{

        try {

            const response = await getInquiryStatus()

            const data = response.result; // Assuming your response contains the data array

            const filteredData = data.filter((item) => item.status !== 'PENDING');

            // Transform data for setCountries
            const countriesData = filteredData.map((country) => ({
                ...country,
                label: country.status.toUpperCase(),
                value: country.status.toUpperCase(), // Assuming you want the value in lowercase
            }));
            setInquiryStatus(countriesData)
        }catch (error){
            // toast.error(t("ERROR_FETCH_PAYEE_REGISTER_DATA"))
        }
    }


    const validationSchema = yup.object().shape({
      //  name: yup.string().required(t("NAME_IS_REQUIRED")),
        //email: yup.string().email(t("EMAIL_MUST_BE_VALID")).required(t("EMAIL_IS_REQUIRED")),
        // messageTitle: yup.string().required(t("MESSAGE_TITLE_IS_REQUIRED")).max(150,t("MESSAGE_TITLE_MUST_BE_AT_MOST_150_CHARACTERS"))
        //     .min(5,t("MESSAGE_TITLE_MUST_BE_AT_LEAST_5_CHARACTERS")),
        // message: yup.string().required(t("MESSAGE_IS_REQUIRED")),
        actions: yup.string().required(t("ACTIONS_IS_REQUIRED"))
            .notOneOf([null, t("SELECT_AN_OPTION")], t("PLEASE_SELECT_AN_ACTION")),
        remarks: yup.string().required(t("MESSAGE_IS_REQUIRED")),
    })


    const onSubmit = async (values) =>{

        setLoading(true)


        let coUserId = clickedEditRowData.coUserId ? clickedEditRowData.coUserId : ''
        let inquiryCode = clickedEditRowData.inquiryId ? clickedEditRowData.inquiryId : ''
        let inquiryId = clickedEditRowData.inquiryIdPk ? clickedEditRowData.inquiryIdPk : ''
        let backofficeUserId = backOfficeUserDTO.backofficeUserId ? backOfficeUserDTO.backofficeUserId : ''

        let data = {
            backofficeUserId: backofficeUserId,
            coUserId: coUserId,
            description: values.message,
            inquiryCode:inquiryCode,
            inquiryId: inquiryId,
            response: values.remarks,
            status: values.actions.toUpperCase(),
            title: values.messageTitle
        }

        try {
            const response = await InquiryRegister(data)
           toast.success(t("REGISTER_UPDATED_SUCCESS"))
            setLoading(false)
            fetchPayeeRegisterData()
            toggleModal()
        }catch (error){
            setLoading(false)
            toast.error(t("ERROR_INQUIRY_UPDATED"))
        }

    }

    const badgeColor = clickedEditRowData.status === "PENDING" ? "bg-warning" :
        clickedEditRowData.status === "COMPLETED" ? "bg-custom-success" :
            clickedEditRowData.status === "REJECTED" ? "bg-danger" : "bg-success";
    const badgeText = clickedEditRowData.status;

    return (
        <div>
            <Modal isOpen={isOpen} centered={true} size="lg">
                <Formik
                    enableReinitialize
                    initialValues={{
                        name: clickedEditRowData.firstName ? clickedEditRowData.firstName : '',
                        date: clickedEditRowData.date ? clickedEditRowData.date : '',
                        email: clickedEditRowData.email ? clickedEditRowData.email : '',
                        inquiryId: clickedEditRowData.inquiryId ? clickedEditRowData.inquiryId : '',
                        messageTitle: clickedEditRowData.messageTitle ? clickedEditRowData.messageTitle : "",
                        message: clickedEditRowData.description ? clickedEditRowData.description : "",
                        actions: clickedEditRowData.status ? clickedEditRowData.status : "",
                        remarks: clickedEditRowData.response ? clickedEditRowData.response : "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ isSubmitting, submitForm }) => (
                        <Form>
                            <ModalHeader style={{backgroundColor: 'rgb(3, 35, 112)', color: 'white' }}>
                                {t("UPDATE_INQUIRY")}
                            </ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="date">{t("DATE")}
                                                <sup className="text-danger"> *</sup>
                                            </Label>
                                            <FormTextField
                                                name="date"
                                                id="date"
                                                placeholder={t("DATE")}
                                                disabled={true}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="status">{t("STATUS")}
                                            </Label>
                                            <br/>
                                            <Badge className={`font-size-14 p-2 ${badgeColor}`}>{badgeText}</Badge>
                                        </FormGroup>
                                    </Col>

                                    <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="email">{t("INQUIRY_ID")}
                                                <sup className="text-danger"> *</sup>
                                            </Label>
                                            <FormTextField
                                                name="inquiryId"
                                                id="inquiryId"
                                                placeholder={t("INQUIRY_ID")}
                                                disabled={true}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="name">{t("NAME")}
                                                <sup className="text-danger"> *</sup>
                                            </Label>
                                            <FormTextField
                                                name="name"
                                                id="name"
                                                placeholder={t("NAME")}
                                                disabled={true}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={12} xl={12}>
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="messageTitle">{t("MESSAGE_TITLE")}
                                                <sup className="text-danger"> *</sup>
                                            </Label>
                                            <FormTextField
                                                name="messageTitle"
                                                type="messageTitle"
                                                id="messageTitle"
                                                placeholder={t("MESSAGE_TITLE")}
                                                disabled={true}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={12} xl={12}>
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="message">{t("MESSAGE")}
                                                <sup className="text-danger"> *</sup>
                                            </Label>
                                            <FormTextField
                                                style={{ minHeight: '100px' }}
                                                name="message"
                                                type="textarea"
                                                id="message"
                                                placeholder={t("MESSAGE")}
                                                disabled={true}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="actions">
                                                {t("ACTIONS")}
                                                <sup className="text-danger"> *</sup>
                                            </Label>
                                            <FormDropdown
                                                name="actions"
                                                id="actions"
                                                optionsValues={isInquiryStatus}
                                                disabled={clickedEditRowData.status && (clickedEditRowData.status === "PENDING") ? false : true}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="remarks">{t("MESSAGE_FOR_USER")}
                                                <sup className="text-danger"> *</sup>
                                            </Label>
                                            <FormTextField
                                                name="remarks"
                                                id="remarks"
                                                placeholder={t("MESSAGE_FOR_USER")}
                                                disabled={clickedEditRowData.status && (clickedEditRowData.status === "PENDING") ? false : true}
                                            />
                                        </FormGroup>
                                    </Col>


                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button className="secondary w-md" onClick={toggleModal}>
                                    {t("CLOSE")}
                                </Button>
                                {
                                    clickedEditRowData.status && (clickedEditRowData.status === "PENDING") ? (
                                        <Button className="btn-primary w-md" type="submit">
                                            {t("SUBMIT")}{' '}{' '}
                                            { loading ? (
                                                <Spinner size="sm">
                                                    Loading...
                                                </Spinner>
                                            ) : "" }
                                        </Button>
                                    ) : (null)
                                }

                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    );

}

export default InquiryEditModel;
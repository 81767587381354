import { get, post, put } from "../helpers/api_helper"
import * as url from "./url_helper"
import {GET_All_KYB_USER_DETAILS} from "./url_helper";


// add Events
export const GetAllKYBUsers = () => get(url.GET_ALL_KYB_USERS)
export const GetAllKYCUsers = () => get(url.GET_All_KYB_USER_DETAILS)
export const GetKYBUserDetailsById = (airewallexId) => post(url.GET_KYB_USER_DETAILS_BY_ID+airewallexId)

export const updateUserDetails = (data) => put(url.UPDATE_USER_DETAILS, data)



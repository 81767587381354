import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle, Label, Input, Badge } from "reactstrap";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { toast } from "react-toastify";
import MaterialTableCustom from "../../common/tables/MaterialTableCustom";
import { GetTransationHistory, GetConfirmationLetter } from "../../services/TransactionHistoryService";
import {GetAllKYCUsers} from "../../services/KYBDashboard";
import { Avatar } from "@material-ui/core";
import PayeeTransactionEditModal from "./payeeTransactionEditModal";
import {useSelector} from "react-redux";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const GlobalAccount = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [users, setUsers] = useState([]);
  const [nameFilter, setNameFilter] = useState();
  const [statusFilter, setStatusFilter] = useState();
  const [fromDateFilter, setFromDateFilter] = useState();
  const [toDateFilter, setToDateFilter] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const user = useSelector(state => state.auth.data.backOfficeUserDTO)

  const hasReadPermission = (permissionType) => {
    const module = user?.permissions?.find(perm => perm.moduleCode === "TRANSACTION_HISTORY");
    return module ? JSON.parse(module.permissions)[permissionType] : false;
  };

  const GetTransactionData = async () => {
    setLoading(true);
    try {
      let data = {
        "startDate": fromDateFilter ? fromDateFilter : null,
        "endDate": toDateFilter ? toDateFilter : null,
        "status": statusFilter ? statusFilter : null,
        "coUserId": nameFilter ? nameFilter : null
      }
      const response = await GetTransationHistory(data);

      const sortedData = response.result.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB - dateA;
      });

      let registerData = sortedData.map((registerData) => {
        let formattedDate = "";

        if (registerData.completed_date) {
          // Parse the date string and format it
          const parsedDate = new Date(registerData.completed_date);

          // Format the hours and minutes with leading zeros
          const formattedHours = ("0" + parsedDate.getHours()).slice(-2);
          const formattedMinutes = ("0" + parsedDate.getMinutes()).slice(-2);

          formattedDate = `${parsedDate.getFullYear()}-${
            parsedDate.getMonth() + 1
          }-${parsedDate.getDate()}, ${formattedHours}:${formattedMinutes}`;
        } else {
          formattedDate = "";
        }

        let errorMessage = registerData.error_message;

        if (errorMessage && errorMessage.includes("beneficiary.address.")) {
          // Extract street_address from the error message
          errorMessage = errorMessage.replace("beneficiary.address.", "");
        }

        // if (errorMessage){
        //     errorMessage = `ERROR : ${errorMessage}`
        // }

        return {
          ...registerData,
          date_and_time: formattedDate,
          payee_name: registerData.payee_nick_name,
          paid_currency: registerData.fee_currency,
          paid_amount: `${registerData.payment_amount} ${registerData.fee_currency || ""}`,
          payee_transaction_status: registerData.status,
          purpose: registerData.reason,
          paid_by: registerData.fee_paid_by,
          conversion_Currency: registerData.conversion_currency,
          transfer_amount: `${registerData.transfer_amount} ${registerData.payee_currency}`,
          errorMessage: errorMessage,
        };
      });

      const modifiedData = registerData.map((item) => {
        // Check payee_transaction_status and add the appropriate Badge
        const badgeColor =
          item.payee_transaction_status.toLowerCase() === "pending"
            ? "bg-warning"
            : item.payee_transaction_status.toLowerCase() === "approved"
            ? "bg-custom-success"
            : item.payee_transaction_status.toLowerCase() === "failed"
            ? "bg-danger"
                      : item.payee_transaction_status.toLowerCase() === "ready_for_dispatch" ? "bg-ready-for-dispatch"
                      : item.payee_transaction_status.toLowerCase() === "in_review" ? "bg-in-review"
                      : item.payee_transaction_status.toLowerCase() === "dispatched" ? "bg-dispatched"
            : "bg-success";
        const badgeText =
          item.payee_transaction_status.toLowerCase() === "pending"
            ? "PENDING"
            : item.payee_transaction_status.toLowerCase() === "approved"
            ? "APPROVED"
            : item.payee_transaction_status.toLowerCase() === "failed"
            ? "FAILED"
            : `${item.payee_transaction_status.toUpperCase()}`;

        return {
          ...item,
          status: badgeText,
          payee_transaction_status: (
            <Badge className={`py-1 ${badgeColor}`}>{badgeText.replace(/_/g, ' ')}</Badge>
          ),
        };
      });

      
      setLoading(false);
      setTransactionHistory(modifiedData);
    } catch (error) {
      toast.error("An error occurred while fetching Transaction History.");
    }
  };

  const fetchKYBDashboardUsers = async () =>{
    setLoading(true)
    try {
        const response = await GetAllKYCUsers()
        let data = response.result.map((coUser) => ({
          label: coUser.firstName + " " + coUser.lastName + " - " + coUser.businessEmail,
          value: coUser.coUserId,
        }));
        console.log("response", data)
        setUsers(data)
        setLoading(false)
    } catch (error) {
        toast.error("Error: Fetch KYB Users")
        setLoading(false)
    }
}

const downloadConfirmationLetter = async (data) => {
  // console.log("Download Confirmation Letter", data)
  setLoading(true)
  try {
    let request = {
      coUserId: data.co_user_id,
      paymentId: data.payment_id
    }
    const response = await GetConfirmationLetter(request)
    const base64Data = response.result;
    console.log("base64Data", base64Data)

    //Convert Base64 string to Blob
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Create a URL for the Blob and open it in a new tab
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, '_blank');
  } catch (error) {
    toast.error("Error: Confirmation Letter Not Available")
  } finally {
    setLoading(false)
  }
}


  useEffect( () => {
    const fetchData = async () => {
      await fetchKYBDashboardUsers();
      GetTransactionData();
    }

    fetchData();
  }, []);

  useEffect(() => {
    if(nameFilter !== undefined || statusFilter !== undefined || fromDateFilter !== undefined || toDateFilter !== undefined){
    GetTransactionData();}
  }, [nameFilter, statusFilter, fromDateFilter, toDateFilter]);

  const handleRowView = async (rowData) => {
    setSelectedTransaction(rowData);
    modalOpenClose();
  }

  const modalOpenClose = () => {
    setIsModalOpen(!isModalOpen);
  }

  document.title = `${t("TRANSACTION_HISTORY")} | ${t("TPS_BACKOFFICE")}`;

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title={t("TPS_BACKOFFICE")} breadcrumbItem={t("TRANSACTION_HISTORY")} />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle>{t("TRANSACTION_HISTORY")}</CardTitle>
                <Row className="mb-3">
                <Col md={4}>
                    <Label for="fromDateFilter">{t("START_DATE")}</Label>
                    <Input
                      id="fromDateFilter"
                      type="date"
                      value={fromDateFilter}
                      onChange={e => setFromDateFilter(e.target.value)}
                      // placeholder={t("ENTER_NAME")}
                    />
                  </Col>
                  <Col md={4}>
                    <Label for="toDateFilter">{t("END_DATE")}</Label>
                    <Input
                      id="toDateFilter"
                      type="date"
                      value={toDateFilter}
                      onChange={e => setToDateFilter(e.target.value)}
                      // placeholder={t("ENTER_NAME")}
                    />
                  </Col>
                  <Col md={4}>
                    <Label for="nameFilter">{t("NAME")}</Label>
                    <Input
                      id="nameFilter"
                      type="select"
                      value={nameFilter}
                      onChange={e => setNameFilter(e.target.value)}
                      placeholder={t("ENTER_NAME")}
                    >
                      <option value="">{t("USERS")}</option>
                      {users.map((user) => (
                        <option key={user.value} value={user.value}>
                          {user.label}
                        </option>
                      ))}
                      </Input>
                  </Col>
                  <Col md={4}>
                    <Label for="statusFilter">{t("STATUS")}</Label>
                    <Input
                      id="statusFilter"
                      type="select"
                      value={statusFilter}
                      onChange={e => setStatusFilter(e.target.value)}
                    >
                      <option value="">{t("STATUS")}</option>
                      <option value="PENDING">{"PENDING"}</option>
                      <option value="NEW">{"NEW"}</option>
                      <option value="REJECTED">{"REJECTED"}</option>
                      <option value="IN_REVIEW">{"IN REVIEW"}</option>
                      <option value="FAILED">{"FAILED"}</option>
                      <option value="READY_FOR_DISPATCH">{"READY FOR DISPATCH"}</option>
                      <option value="DISPATCHED">{"DISPATCHED"}</option>
                    </Input>
                  </Col>
                </Row>
                <MaterialTableCustom
                  columns={[
                    { title: t("DATE"), field: "date_and_time", width: 200 },
                    // { title: t("TRANSACTION_TYPE"), field: "lastName", width: 200 },
                    { title: t("PAYER"), field: "co_user_first_name", width: 200 },
                    { title: t("STATUS"), field: "payee_transaction_status", width: 200 },
                    { title: t("AMOUNT"), field: "paid_amount", width: 200 },
                  ]}
                  data={hasReadPermission("VIEW") ? transactionHistory : []}
                  loading={loading}
                  tableRowViewHandler={hasReadPermission("VIEW") ? handleRowView : null}
                  // clickHistoryButtion={downloadConfirmationLetter}
                  // isDownloadDocuments={hasReadPermission("PRINT")}
                  customAction={
                    (selectedRows) => {

                        return (hasReadPermission("PRINT") && selectedRows.status === "DISPATCHED" ?
                            {
                              icon:()=><FileDownloadIcon style={{color:'#757575FF'}}/>,
                              tooltip: 'Download',
                              disabled: false,
                              onClick: (event, selectedRow) => {
                                downloadConfirmationLetter(selectedRow)
                              },
                            } : null)

                    }
                  }
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {isModalOpen && (
            <PayeeTransactionEditModal
              rowData={selectedTransaction}
              isOpen={isModalOpen}
              toggleModal={modalOpenClose}
            />
          )}
    </div>
  );
};

export default GlobalAccount;

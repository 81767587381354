import React, {useEffect, useState} from "react";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container, FormGroup, Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row, Spinner
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import MaterialTableCustom from "../../common/tables/MaterialTableCustom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getAllContactRequest} from "../../services/ContactRequestService";
import {toast} from "react-toastify";

const ContactRequest = (props) => {

    const [ContactRequestData, setContactRequestData] = useState([])
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation()

    const user = useSelector(state => state.auth.data.backOfficeUserDTO)

    const hasReadPermission = (permissionType) => {
        const module = user?.permissions?.find(perm => perm.moduleCode === "CONTACT_REQUEST");
        return module ? JSON.parse(module.permissions)[permissionType] : false;
    };

    const [columns, setColumns] = useState([
        { title: t("NAME"), field: 'name',width: 150},
        { title: t("EMAIL"), field: 'email',width: 150},
        { title: t("MOBILE_NUMBER"), field: 'mobile',width: 150},
        { title: t("RECIEVED_DATE"), field: 'date_and_time',width: 150},
    ]);

    useEffect(()=>{
            fetchInquiryData()
    },[])


    const fetchInquiryData = async () =>{

        try {

            const response = await getAllContactRequest()

            const data = response.result; // Assuming your response contains the data array

           try{
            let modifiedData = data.map((responseData) => {
                let formattedDate = "";

                if (responseData.createdAt) {
                    // Parse the date string and format it
                    const parsedDate = new Date(responseData.createdAt);

                    // Format the hours and minutes with leading zeros
                    const formattedHours = ("0" + parsedDate.getHours()).slice(-2);
                    const formattedMinutes = ("0" + parsedDate.getMinutes()).slice(-2);

                    formattedDate = `${parsedDate.getFullYear()}-${
                        parsedDate.getMonth() + 1
                    }-${parsedDate.getDate()}, ${formattedHours}:${formattedMinutes}`;
                } else {
                    formattedDate = "";
                }

                return {
                    ...responseData,
                    date_and_time: formattedDate,
                };
            });
            setTableData(modifiedData)
           } catch (er){
            toast.error(er)
           }

            // setTableData(data)
        }catch (error){
            toast.error(t("ERROR_CONTACT_REQUEST"))
        }
    }

    document.title = `${t("CONTACT_REQUEST")} | ${t("TPS_BACKOFFICE")}`;


    return(
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="page-title-box p-0">
                        <Row>
                            <Col md={6}>
                                <Breadcrumbs title="TPS Solutions" breadcrumbItem={t("CONTACT_REQUEST")} mainTitleLink="/" titleLink="#" />
                            </Col>

                        </Row>
                    </div>

                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="h4 mb-3">{t("CONTACT_REQUEST")}</CardTitle>

                                    {/*<MDBDataTable responsive striped bordered data={data} />*/}
                                    <div className="custom-table">
                                        <MaterialTableCustom
                                            data={hasReadPermission("VIEW") ? tableData : []}
                                            columns={columns}
                                            loading={loading}
                                            isSearch={false}
                                        />
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
            </div>

        </React.Fragment>
    )
}

export default ContactRequest;

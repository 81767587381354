import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {Badge, Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";


const KycModel = (props) => {
    const { isOpen, toggleModal, rowData } = props;

    const { t } = useTranslation();

    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggleModal} centered={true} size="lg">

                <ModalHeader style={{backgroundColor: 'rgb(3, 35, 112)', color: 'white' }}>
                                {t("KYC_USER")}
                            </ModalHeader>
                            <ModalBody>
                                <Row>

                                    <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                        <Label htmlFor="firstName">{t("FIRST_NAME")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="firstName"
                                            placeholder={t("FIRST_NAME")}
                                            type="text"
                                            className="form-control"
                                            id="firstName"
                                            value={rowData.firstName}
                                            disabled={true}
                                        />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                        <Label htmlFor="lastName">{t("LAST_NAME")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="lastName"
                                            placeholder={t("LAST_NAME")}
                                            type="text"
                                            className="form-control"
                                            id="lastName"
                                            value={rowData.lastName}
                                            disabled={true}
                                        />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                        <Label htmlFor="email">{t("EMAIL")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="email"
                                            placeholder={t("EMAIL")}
                                            type="text"
                                            className="form-control"
                                            id="email"
                                            value={rowData.businessEmail}
                                            disabled={true}
                                        />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                        <Label htmlFor="country">{t("COUNTRY")}
                                            <sup className="text-danger"> *</sup>
                                        </Label>
                                        <Input
                                            name="country"
                                            placeholder={t("COUNTRY")}
                                            type="text"
                                            className="form-control"
                                            id="country"
                                            value={rowData.country}
                                            disabled={true}
                                        />
                                        </FormGroup>
                                    </Col>

                                    <Col md={6} xl={6}>
                                        <FormGroup className="mb-3">
                                            <Label htmlFor="status">{t("STATUS")}</Label>
                                            <br/>
                                            {<div className="f-14">{
                                                rowData?.kycSubmitStatus?.toLowerCase() === "active" ? <Badge className={`py-1 bg-active`}>{rowData.kycSubmitStatus}</Badge> :
                                                rowData?.kycSubmitStatus?.toLowerCase() === "pending" ? <Badge className={`py-1 bg-in-review`}>{rowData.kycSubmitStatus}</Badge> :
                                                rowData?.kycSubmitStatus?.toLowerCase() === "submitted" ? <Badge className={`py-1 bg-new`}>{rowData.kycSubmitStatus}</Badge> :
                                                rowData?.kycSubmitStatus?.toLowerCase() === "failed" ? <Badge className={`py-1 bg-danger`}>{rowData.kycSubmitStatus}</Badge> :
                                                    <Badge className={`py-1 bg-ready-for-dispatch`}>{rowData.kycSubmitStatus}</Badge>
                                            }</div>}

                                        </FormGroup>
                                    </Col>
                                </Row>

                            </ModalBody>
                            <ModalFooter>
                                <Button className="secondary w-md" onClick={toggleModal}>
                                    {t("CLOSE")}
                                </Button>
                                {/*<Button color="secondary w-md" type="submit">*/}
                                {/*    { isNew ? t("SAVE") : t("UPDATE")}*/}
                                {/*</Button>*/}
                            </ModalFooter>
            </Modal>
        </div>
    );

};

export default KycModel;
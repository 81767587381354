import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardTitle,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { toast } from "react-toastify";
import {
    GetActiveUserRoles,
    GetModules,
    SavePermissions,
} from "../../services/UserRoleManagement";
import { useSelector } from "react-redux";

const AccessManagement = () => {
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState("");
    const [modules, setModules] = useState([]);
    const [permissions, setPermissions] = useState({});
    const { t } = useTranslation();

    const user = useSelector((state) => state.auth.data.backOfficeUserDTO);

    const hasReadPermission = (permissionType) => {
        const module = user?.permissions?.find(
            (perm) => perm.moduleCode === "ACCESS_MANAGEMENT"
        );
        return module ? JSON.parse(module.permissions)[permissionType] : false;
    };

    useEffect(() => {
        fetchRoles();
        fetchModules();
    }, []);

    const fetchRoles = async () => {
        try {
            const response = await GetActiveUserRoles();
            setRoles(response.result);
        } catch (error) {
            toast.error("Error: fetching roles");
        }
    };

    const fetchModules = async () => {
        try {
            const response = await GetModules();
            setModules(response.result);
        } catch (error) {
            toast.error("Error: fetching modules");
        }
    };

    // Fetch saved permissions when a role is selected
    const handleRoleSelect = async (roleId) => {
        setSelectedRole(roleId);

        // Find the selected role from the roles array
        const selectedRole = roles.find((role) => role.roleId === roleId);

        if (selectedRole) {
            const savedPermissions = selectedRole.permissions;

            // Pre-populate the permissions table with saved data using moduleId
            const formattedPermissions = savedPermissions.reduce((acc, permission) => {
                const parsedPermissions = JSON.parse(permission.permissions);

                acc[permission.moduleId] = {
                    permissionId: permission.permissionId || null,
                    VIEW: parsedPermissions.VIEW || false,
                    CREATE: parsedPermissions.CREATE || false,
                    UPDATE: parsedPermissions.UPDATE || false,
                    PRINT: parsedPermissions.PRINT || false,
                    DELETE: parsedPermissions.DELETE || false,
                };
                return acc;
            }, {});
            setPermissions(formattedPermissions);
        } else {
            // If no role is found, reset permissions
            setPermissions({});
        }
    };

    // Handle individual permission change
    const handlePermissionChange = (moduleId, permissionType, isChecked) => {
        setPermissions((prevPermissions) => ({
            ...prevPermissions,
            [moduleId]: {
                ...prevPermissions[moduleId],
                [permissionType]: isChecked,
            },
        }));
    };

    // Select/Deselect all permissions for a specific module
    const handleSelectAllModule = (moduleId, isChecked) => {
        setPermissions((prevPermissions) => ({
            ...prevPermissions,
            [moduleId]: {
                ...prevPermissions[moduleId],
                VIEW: isChecked,
                CREATE: isChecked,
                UPDATE: isChecked,
                PRINT: isChecked,
                DELETE: isChecked,
            },
        }));
    };

    // Select/Deselect a permission type for all modules
    const handleSelectAllPermissionType = (permissionType, isChecked) => {
        setPermissions((prevPermissions) =>
            modules.reduce((acc, module) => {
                acc[module.moduleId] = {
                    ...prevPermissions[module.moduleId],
                    [permissionType]: isChecked,
                };
                return acc;
            }, { ...prevPermissions })
        );
    };

    const savePermissions = async () => {
        const payload = modules.map((module) => ({
            roleId: selectedRole,
            moduleId: module.moduleId,
            moduleName: module.moduleName,
            permissionId: permissions[module.moduleId]?.permissionId || null,
            permissions: JSON.stringify({
                VIEW: permissions[module.moduleId]?.VIEW || false,
                CREATE: permissions[module.moduleId]?.CREATE || false,
                UPDATE: permissions[module.moduleId]?.UPDATE || false,
                PRINT: permissions[module.moduleId]?.PRINT || false,
                DELETE: permissions[module.moduleId]?.DELETE || false,
            }),
        }));

        try {
            await SavePermissions({ userRolePermissions: payload });
            toast.success("Permissions saved successfully");
            window.location.reload();
        } catch (error) {
            toast.error("Error: saving permissions");
        }
    };

    document.title = `${t("ACCESS_MANAGEMENT")} | ${t("TPS_BACKOFFICE")}`;

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={t("TPS_BACKOFFICE")}
                        breadcrumbItem={t("ACCESS_MANAGEMENT")}
                    />

                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="h4">
                                        {t("ACCESS_MANAGEMENT")}
                                    </CardTitle>

                                    <FormGroup>
                                        <Label for="roleSelect">{t("Select Role")}</Label>
                                        <Input
                                            type="select"
                                            name="role"
                                            id="roleSelect"
                                            value={selectedRole}
                                            onChange={(e) =>
                                                handleRoleSelect(e.target.value)
                                            }
                                        >
                                            <option value="" disabled>
                                                {t("SELECT_ROLE")}
                                            </option>
                                            {hasReadPermission("VIEW") &&
                                                roles.map((role) => (
                                                    <option key={role.roleId} value={role.roleId}>
                                                        {role.roleName}
                                                    </option>
                                                ))}
                                        </Input>
                                    </FormGroup>

                                    {selectedRole && (
                                        <div className="permissions-table">
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th>{t("MODULE_NAME")}</th>
                                                    {["VIEW", "CREATE", "UPDATE", "PRINT"].map(
                                                        (perm) => (

                                                            <th key={perm}>
                                                                <span>{t(perm)}</span> <br></br>
                                                                <span style={{marginLeft: "10px"}}>
                                                                    <input
                                                                        type="checkbox"
                                                                        onChange={(e) =>
                                                                            handleSelectAllPermissionType(
                                                                                perm,
                                                                                e.target.checked
                                                                            )
                                                                        }
                                                                    />{" "}
                                                                </span>
                                                            </th>
                                                        )
                                                    )}
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {hasReadPermission("VIEW") &&
                                                    modules.map((module) => (
                                                        <tr key={module.moduleId}>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    onChange={(e) =>
                                                                        handleSelectAllModule(
                                                                            module.moduleId,
                                                                            e.target.checked
                                                                        )
                                                                    }
                                                                />{" "}

                                                                    {module.moduleName}</td>
                                                            {[
                                                                "VIEW",
                                                                "CREATE",
                                                                "UPDATE",
                                                                "PRINT",
                                                                // "DELETE",
                                                            ].map((perm) => (
                                                                <td key={perm}>
                                                                    <span style={{marginLeft: "10px"}}>
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={
                                                                                permissions[
                                                                                    module.moduleId
                                                                                    ]?.[perm] || false
                                                                            }
                                                                            onChange={(e) =>
                                                                                handlePermissionChange(
                                                                                    module.moduleId,
                                                                                    perm,
                                                                                    e.target.checked
                                                                                )
                                                                            }
                                                                        />
                                                                    </span>

                                                                </td>
                                                            ))}

                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    <Button
                                        color="primary"
                                        onClick={savePermissions}
                                        disabled={
                                            !(hasReadPermission("UPDATE") && selectedRole)
                                        }
                                    >
                                        {t("SAVE_PERMISSIONS")}
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AccessManagement;

import React, { useEffect, useState } from "react";
import * as yup from "yup";
import {
  Badge,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row, Spinner,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import FormTextField from "../../../common/formComponents/FormTextField";
import FormDropdown from "../../../common/formComponents/FormDropdown";
import { CreateUserRole, UpdateUserRole } from "../../../services/UserRoleManagement";
import { toast } from "react-toastify";

const UserRoleModel = (props) => {
  const { isOpen, toggleModal, rowData, isNew } = props;

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState([
    { value: t("ACTIVE"), label: "Active" },
    { value: t("DEACTIVATE"), label: "Deactivate" },
  ]);

  const onSubmit = async (values) => {
    console.log(values)
    setLoading(true)
    try {
      const data = {
        "roleId": rowData?.roleId ? rowData?.roleId : "",
        "roleName": values.roleName,
        "isActive": true,
        "roleDescription": values.roleDescription,
      }

      if(isNew){
        const response = await CreateUserRole(data)
        props.fetchTPSUserRoles()
        setLoading(false)
        toast.success(t("REGISTER_USER_SUCCESS"))
        toggleModal()
      }else{
        const response = await UpdateUserRole(data)
        props.fetchTPSUserRoles()
        setLoading(false)
        toast.success(t("UPDATE_USER_SUCCESS"))
        toggleModal()
      }
    } catch (error) {
      if (error.response.data.statusMessage === "Failure"){
        setLoading(false)
        toast(`Error: ${error.response.data.result.error[0]} `)
      }else {
        setLoading(false)
        toast.error(`Error: ${isNew ? t("USER_CREATE") : t("USER_UPDATE")} `)
      }
    }
  }

  const validationSchema = yup.object().shape({
    roleName: yup.string().required(t("NAME_IS_REQUIRED")),
    roleDescription: yup.string().required(t("DESCRIPTION_IS_REQUIRED"))
    // status: yup.string().required(t("STATUS_IS_REQUIRED"))
    //     .notOneOf([null, t("SELECT_AN_OPTION")], t("PLEASE_SELECT_STATUS"))
    ,
  });


  return (
    <div>
      <Modal isOpen={isOpen} centered={true} size="lg">
        <Formik
          enableReinitialize
          initialValues={{
            roleId: rowData?.roleId ? rowData?.roleId : "",
            roleName: rowData?.roleName ? rowData?.roleName : "",
            roleDescription: rowData?.roleDescription ? rowData?.roleDescription : "",
            status: rowData?.isActive === true ? "Active" : rowData?.isActive === false ? "Deactivate" : "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, submitForm }) => (
            <Form>
              <ModalHeader style={{backgroundColor: 'rgb(3, 35, 112)', color: 'white' }}>
                {isNew ? t("CREATE") : t("EDIT")} {t("USER_ROLE")}
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={6} xl={6}>
                    <FormGroup className="mb-3">
                      <Label htmlFor="roleName">{t("ROLE_NAME")}
                        <sup className="text-danger"> *</sup>
                      </Label>
                      <FormTextField
                        name="roleName"
                        id="roleName"
                        placeholder={t("ROLE_NAME")}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={6} xl={6}>
                    <FormGroup className="mb-3">
                      <Label htmlFor="roleDescription">{t("ROLE_DESCRIPTION")}
                        <sup className="text-danger"> *</sup>
                      </Label>
                      <FormTextField
                        name="roleDescription"
                        id="roleDescription"
                        placeholder={t("ROLE_DESCRIPTION")}
                      />
                    </FormGroup>
                  </Col>
                  {
                    !isNew &&
                      <Col md={6} xl={6}>
                        <FormGroup className="mb-3">
                          <Label htmlFor="status">{t("SELECT_STATUS")}
                            <sup className="text-danger"> *</sup>
                          </Label>
                          <FormDropdown
                              name="status"
                              id="status"
                              disabled={true}
                              placeholder={t("SELECT_STATUS")}
                              optionsValues={status}
                          />
                        </FormGroup>
                      </Col>
                  }

                </Row>
              </ModalBody>
              <ModalFooter>
                <Button className="secondary w-md" onClick={toggleModal}>
                  {t("CLOSE")}
                </Button>
                <Button className="btn-primary w-md" type="submit">
                   { isNew ? t("SAVE") : t("UPDATE")}{' '}{' '}
                  { loading ? (
                      <Spinner size="sm">
                        Loading...
                      </Spinner>
                  ) : "" }
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default UserRoleModel;

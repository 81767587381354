import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle, Label, Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { toast } from "react-toastify";
import MaterialTableCustom from "../../common/tables/MaterialTableCustom";
import { GetAllGlobalAccount, getBankCurrency } from "../../services/GlobalAccountService";
import { Avatar } from "@material-ui/core";
import {useSelector} from "react-redux";

const GlobalAccount = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [gaBalance, setGABalance] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [filteredGABalance, setFilteredGABalance] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [currencyFilter, setCurrencyFilter] = useState("");

  const user = useSelector(state => state.auth.data.backOfficeUserDTO)

  const hasReadPermission = (permissionType) => {
    const module = user?.permissions?.find(perm => perm.moduleCode === "GLOBAL_ACCOUNT");
    return module ? JSON.parse(module.permissions)[permissionType] : false;
  };

  const getGABalance = async () => {
    setLoading(true);
    console.log("getGABalance called");
    try {
      const response = await GetAllGlobalAccount();
      console.log(response);
      setGABalance(response.result);
    } catch (error) {
      toast.error("An error occurred while fetching Global Account Balance.");
    }
    setLoading(false);
  };

  const handleFilterChange = () => {
    let filteredData = gaBalance;

    if (nameFilter) {
      filteredData = filteredData.filter(user =>
        user.firstName.toLowerCase().includes(nameFilter.toLowerCase()) ||
        user.lastName.toLowerCase().includes(nameFilter.toLowerCase())
      );
    }

    if (currencyFilter) {
      filteredData = filteredData.map(user => ({
        ...user,
        balance: user.balance ? user.balance.filter(bal => bal.currency_code.toLowerCase() === currencyFilter.toLowerCase()) : []
      })).filter(user => user.balance.length > 0);
    }

    setFilteredGABalance(filteredData);
  };

  const fetchBankCurrency = async () => {
    try {
      const response = await getBankCurrency();

      let bankCurrency = response.result.map((bankCurrency) => ({
        label: bankCurrency.bank_currency,
        value: bankCurrency.bank_currency_code,
      }));

      setCurrencies(bankCurrency);
    } catch (error) {
      toast.error(t("ERROR_FETCH_BANK_CURRENCY"));
    }
  };

  useEffect(() => {
    getGABalance();
    fetchBankCurrency();
  }, []);

  useEffect(() => {
    handleFilterChange();
  }, [nameFilter, currencyFilter, gaBalance]);

  document.title = `${t("GLOBAL_ACCOUNT")} | ${t("TPS_BACKOFFICE")}`;

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title={t("TPS_BACKOFFICE")} breadcrumbItem={t("GLOBAL_ACCOUNT")} />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle>{t("GLOBAL_ACCOUNT")}</CardTitle>
                <Row className="mb-3">
                  <Col md={4}>
                    <Label for="nameFilter">{t("NAME")}</Label>
                    <Input
                      id="nameFilter"
                      type="text"
                      value={nameFilter}
                      onChange={e => setNameFilter(e.target.value)}
                      placeholder={t("ENTER_NAME")}
                    />
                  </Col>
                  <Col md={4}>
                    <Label for="currencyFilter">{t("CURRENCY")}</Label>
                    <Input
                      id="currencyFilter"
                      type="select"
                      value={currencyFilter}
                      onChange={e => setCurrencyFilter(e.target.value)}
                    >
                      <option value="">{t("CURRENCY")}</option>
                      {currencies.map((currency) => (
                        <option key={currency.value} value={currency.value}>
                          {currency.label}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
                <MaterialTableCustom
                  columns={[
                    { title: t("FIRST_NAME"), field: "firstName", width: 200 },
                    { title: t("LAST_NAME"), field: "lastName", width: 200 },
                    { title: t("EMAIL"), field: "businessEmail", width: 200 },
                    {
                      title: t("CURRENCY"),
                      field: "balance",
                      width: 200,
                      render: rowData => {
                        if (rowData.balance && rowData.balance.length > 0) {
                          return (
                            <Row>
                              {rowData.balance.map((item, index) => (
                                <div key={index} className="mt-1" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                  {index > 0 && <hr style={{ width: '100%', margin: '5px 0' }} />}
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span>{item.currency_code}</span>
                                    <Avatar
                                      alt="Country Flag"
                                      src={item.flag}
                                      style={{ width: 20, height: 20, marginLeft: 5 }} // Add some space between the items
                                    />
                                  </div>
                                </div>
                              ))}
                            </Row>
                          );
                        }
                        return '-';
                      }
                    },
                    {
                      title: t("BALANCE"),
                      field: "balance",
                      width: 200,
                      render: rowData => {
                        if (rowData.balance && rowData.balance.length > 0) {
                          return (
                            <Row>
                              {rowData.balance.map((item, index) => (
                                <div key={index} className="mt-1" style={{ flexDirection: 'column' }}>
                                  {index > 0 && <hr style={{ width: '100%', margin: '5px 0' }} />}
                                  <div>{item.total}</div>
                                </div>
                              ))}
                            </Row>
                          );
                        }
                        return '-';
                      }
                    }
                  ]}
                  data={hasReadPermission("VIEW") ? filteredGABalance : []}
                  loading={loading}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GlobalAccount;

import React, { useEffect, useState } from "react";
import AddBox from "@material-ui/icons/AddBox";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Edit from "@material-ui/icons/Edit";
import SaveAlt from "@material-ui/icons/SaveAlt";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Search from "@material-ui/icons/Search";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable, { MTableToolbar } from "material-table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PaidIcon from "@mui/icons-material/Paid";
import { Grid } from "@material-ui/core";
import PanToolAltIcon from "@mui/icons-material/PanToolAlt";
import { AddCircle } from "@material-ui/icons";
import { IconButton, Tooltip } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const MaterialTableCustom = (props) => {
  const tableIcons = {
    Add: React.forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: React.forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: React.forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: React.forwardRef((props, ref) => (
      <DeleteOutline {...props} ref={ref} />
    )),
    DetailPanel: React.forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: React.forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: React.forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: React.forwardRef((props, ref) => (
      <FilterList {...props} ref={ref} />
    )),
    FirstPage: React.forwardRef((props, ref) => (
      <FirstPage {...props} ref={ref} />
    )),
    LastPage: React.forwardRef((props, ref) => (
      <LastPage {...props} ref={ref} />
    )),
    NextPage: React.forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    PreviousPage: React.forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: React.forwardRef((props, ref) => (
      <Clear {...props} ref={ref} />
    )),
    Search: React.forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: React.forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: React.forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: React.forwardRef((props, ref) => (
      <ViewColumn {...props} ref={ref} />
    )),
  };

  return (
    <Grid>
      <MaterialTable
        icons={tableIcons}
        isLoading={props.loading}
        columns={props.columns}
        data={props.data}
        // components={{
        //     // Toolbar: (props) => <div style={{ height: '0px' }} />, // Set height to zero
        // }}

        actions={[
          props.handleRowView && {
            icon: () => <VisibilityIcon style={{ color: "green" }} />,
            tooltip: "View",
            disabled: false,
            onClick: (event, selectedRow) => {
              props.handleRowView(selectedRow);
            },
          },
          
          props.handleRowEdit && {
            icon: () => <EditIcon style={{ color: "green" }} />,
            tooltip: "Edit",
            disabled: false,
            onClick: (event, selectedRow) => {
              props.handleRowEdit(selectedRow);
            },
          },
          props.handleRowDelete && {
            icon: () => <DeleteIcon style={{ color: "red" }} />,
            tooltip: "Delete",
            disabled: false,
            onClick: (event, selectedRow) => {
              props.handleRowDelete(selectedRow);
            },
          },
         
          props.isPayVisible && {
            icon: () => <PaidIcon style={{ color: "#3558AE" }} />,
            tooltip: "Pay",
            disabled: false,
            onClick: (event, selectedRow) => {
              props.clickPayButton(selectedRow);
            },
          },
          props.tableRowViewHandler && {
            icon:()=><VisibilityIcon style={{color:'#757575FF'}}/>,
            tooltip: 'View',
            disabled: false,
            onClick: (event, selectedRow) => {
              props.tableRowViewHandler(selectedRow)
            },
          },

          props.isDownloadDocuments && {
            icon:()=><FileDownloadIcon style={{color:'#757575FF'}}/>,
            tooltip: 'Download',
            disabled: false,
            onClick: (event, selectedRow) => {
              props.clickHistoryButtion(selectedRow)
            },
          },

          props.customAction

        ]}
        options={{
          showTitle: false,
          search: props.isSearch,
          actionsColumnIndex: -1,
          //header styles
          headerStyle: {
            background: "#032370",
            color: "white",
          },
        }}
        components={{
            Toolbar: (toolbarProps) => (
              <div>
                <MTableToolbar {...toolbarProps} />
  
                {props.externalAdd && (
                  <Grid
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end", // Align to the right
                    }}
                  >
                    <Tooltip title="External Add">
                      <IconButton 
                      style={{
                        color: "#3558AE", // Set the color to blue
                        fontSize: "500px", // Set the font size to increase the button size
                        marginRight : "20px"
                      }}
                      onClick={props.externalAdd}>
                        <AddCircle />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              </div>
            ),
          }}
      />
    </Grid>
  );
};

export default MaterialTableCustom;

import { get, post, put } from "../helpers/api_helper"
import * as url from "./url_helper"


export const GetUserRoles = () => get(url.GET_TPS_USER_ROLE)
export const CreateUserRole = (data) => post(url.CREATE_TPS_USER_ROLE, data)
export const UpdateUserRole = (data) => post(url.UPDATE_TPS_USER_ROLE, data)
export const GetActiveUserRoles = () => get(url.GET_TPS_ACTIVE_USER_ROLE)
export const GetModules = () => get(url.GET_TPS_MODULE)
export const SavePermissions = (data) => post(url.SAVE_USER_ROLE_PERMISSION, data)



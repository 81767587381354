import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle, Label, Input, Badge } from "reactstrap";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { toast } from "react-toastify";
import MaterialTableCustom from "../../common/tables/MaterialTableCustom";
import { GetFilterCharges } from "../../services/ChargeService";
import {GetAllKYCUsers} from "../../services/KYBDashboard";
import { Avatar } from "@material-ui/core";
import {useSelector} from "react-redux";

const Charges = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [users, setUsers] = useState([]);
  const [nameFilter, setNameFilter] = useState();
  const [statusFilter, setStatusFilter] = useState();
  const [chargeTypeFilter, setChargeTypeFilter] = useState();
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const user = useSelector(state => state.auth.data.backOfficeUserDTO)

  const hasReadPermission = (permissionType) => {
    const module = user?.permissions?.find(perm => perm.moduleCode === "CHARGES");
    return module ? JSON.parse(module.permissions)[permissionType] : false;
  };

  const GetTransactionData = async () => {
    setLoading(true);
    try {
      let data = {
        "status": statusFilter ? statusFilter : null,
        "coUserId": nameFilter ? nameFilter : null,
        "chargeType": chargeTypeFilter ? chargeTypeFilter : null
      }
      const response = await GetFilterCharges(data);

      const sortedData = response.result.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB - dateA;
      });

      const modifiedData = sortedData.map((item) => {
        // Check status and add the appropriate Badge
        const badgeColor =
          item.status.toLowerCase() === "pending"
            ? "bg-warning"
            : item.status.toLowerCase() === "approved"
            ? "bg-custom-success"
            : item.status.toLowerCase() === "failed"
            ? "bg-danger"
                      : item.status.toLowerCase() === "ready_for_dispatch" ? "bg-ready-for-dispatch"
                      : item.status.toLowerCase() === "in_review" ? "bg-in-review"
                      : item.status.toLowerCase() === "dispatched" ? "bg-dispatched"
            : "bg-success";
        const badgeText =
          item.status.toLowerCase() === "pending"
            ? "PENDING"
            : item.status.toLowerCase() === "approved"
            ? "APPROVED"
            : item.status.toLowerCase() === "failed"
            ? "FAILED"
            : `${item.status.toUpperCase()}`;
            
            let formattedDate = "";

        if (item.apiRequestTime) {
          // Parse the date string and format it
          const parsedDate = new Date(item.apiRequestTime);

          // Format the hours and minutes with leading zeros
          const formattedHours = ("0" + parsedDate.getHours()).slice(-2);
          const formattedMinutes = ("0" + parsedDate.getMinutes()).slice(-2);

          formattedDate = `${parsedDate.getFullYear()}-${
            parsedDate.getMonth() + 1
          }-${parsedDate.getDate()}, ${formattedHours}:${formattedMinutes}`;
        } else {
          formattedDate = "";
        }

        let displayAmount = ""

        if(item.chargedAmount && item.chargedCurrency){
          displayAmount = `${item.chargedAmount} ${item.chargedCurrency}`
        } else {
          displayAmount = `${item.amount} ${item.currency}`
        }

        let dispalyName = ""
        if(item.firstName && item.lastName){
          dispalyName = `${item.firstName} ${item.lastName}`
        }

        return {
          ...item,
          date_and_time: formattedDate,
          displayAmount: displayAmount,
          dispalyName: dispalyName,
          chargeType: item.chargeType.replace(/_/g, ' '),
          charge_status: (
            <Badge className={`py-1 ${badgeColor}`}>{badgeText}</Badge>
          ),
        };
      });

      // console.log("modifiedData", modifiedData)
      setTransactionHistory(modifiedData);
    } catch (error) {
      toast.error("An error occurred while fetching Charges.");
    }
    setLoading(false);
  };

  const fetchKYBDashboardUsers = async () =>{
    setLoading(true)
    try {
        const response = await GetAllKYCUsers()
        let data = response.result.map((coUser) => ({
          label: coUser.firstName + " " + coUser.lastName + " - " + coUser.businessEmail,
          value: coUser.coUserId,
        }));
        console.log("response", data)
        setUsers(data)
        setLoading(false)
    } catch (error) {
        toast.error("Error: Fetch Users")
        setLoading(false)
    }
}


  useEffect( () => {
    const fetchData = async () => {
      await fetchKYBDashboardUsers();
      GetTransactionData();
    }
    fetchData();
  }, []);

  useEffect(() => {
    if(nameFilter !== undefined || statusFilter !== undefined || chargeTypeFilter !== undefined){
    GetTransactionData();}
  }, [nameFilter, statusFilter, chargeTypeFilter]);

  document.title = `${t("CHARGES")} | ${t("TPS_BACKOFFICE")}`;

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title={t("TPS_BACKOFFICE")} breadcrumbItem={t("CHARGES")} />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle>{t("CHARGES")}</CardTitle>
                <Row className="mb-3">
                  <Col md={4}>
                    <Label for="nameFilter">{t("NAME")}</Label>
                    <Input
                      id="nameFilter"
                      type="select"
                      value={nameFilter}
                      onChange={e => setNameFilter(e.target.value)}
                      placeholder={t("ENTER_NAME")}
                    >
                      <option value="">{t("USERS")}</option>
                      {users.map((user) => (
                        <option key={user.value} value={user.value}>
                          {user.label}
                        </option>
                      ))}
                      </Input>
                  </Col>
                  <Col md={4}>
                    <Label for="statusFilter">{t("STATUS")}</Label>
                    <Input
                      id="statusFilter"
                      type="select"
                      value={statusFilter}
                      onChange={e => setStatusFilter(e.target.value)}
                    >
                      <option value="">{t("STATUS")}</option>
                      <option value="PENDING">{"PENDING"}</option>
                      <option value="NEW">{"NEW"}</option>
                      <option value="REJECTED">{"REJECTED"}</option>
                      <option value="IN_REVIEW">{"IN REVIEW"}</option>
                      <option value="FAILED">{"FAILED"}</option>
                      <option value="READY_FOR_DISPATCH">{"READY FOR DISPATCH"}</option>
                      <option value="DISPATCHED">{"DISPATCHED"}</option>
                    </Input>
                  </Col>
                  <Col md={4}>
                    <Label for="chargeTypeFilter">{t("CHARGE_TYPE")}</Label>
                    <Input
                      id="chargeTypeFilter"
                      type="select"
                      value={chargeTypeFilter}
                      onChange={e => setChargeTypeFilter(e.target.value)}
                    >
                      <option value="">{t("CHARGE_TYPE")}</option>
                      <option value="CONNECTED_ACCOUNT">{"Connected Account"}</option>
                      <option value="GLOBAL_ACCOUNT">{"Global Account"}</option>
                    </Input>
                  </Col>
                </Row>
                <MaterialTableCustom
                  columns={[
                    { title: t("CHARGE_ID"), field: "airwallexChargeId", width: 200 },
                    { title: t("NAME"), field: "dispalyName", width: 200 },
                    { title: t("AMOUNT"), field: "displayAmount", width: 200 },
                    { title: t("CHARGE_TYPE"), field: "chargeType", width: 200 },
                    { title: t("STATUS"), field: "charge_status", width: 200 },
                    { title: t("DATE"), field: "date_and_time", width: 200 },
                  ]}
                  data={hasReadPermission("VIEW") ? transactionHistory : []}
                  loading={loading}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Charges;

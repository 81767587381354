import React, {useEffect, useState} from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    CardTitle, Badge, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Spinner
} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import {toast} from "react-toastify";
import MaterialTableCustom from "../../common/tables/MaterialTableCustom";
import {useSelector} from "react-redux";
import {GetAllKYCUsers, updateUserDetails} from "../../services/KYBDashboard";
import KycModel from "./components/KycModel";
import EditIcon from "@mui/icons-material/Edit";
import FormTextField from "../../common/formComponents/FormTextField";
import {Form, Formik} from "formik";
import * as yup from "yup";


const KYB_Submit = props => {

    const [editTooltip, setEditTooltip] = useState(false);
    const [isOpenPayeeTransactionHistoryModal, setIsOpenPayeeTransactionHistoryModal] = useState(false);
    const [clickedRowData, setClickedRowData] = useState(null);
    const toggleTransactionHistoryModal = () => setIsOpenPayeeTransactionHistoryModal(!isOpenPayeeTransactionHistoryModal);

    const [isDeleteModal, setDeleteModal] = useState(false);
    const deleteToggleModal = () => setDeleteModal(!isDeleteModal);
    const [selectedRowData, setSelectedRowData] = useState(null)

    const [isDeleteRowData, setDeleteRowData] = useState({});

    const [kycModel, setKycModel] = useState(false);
    const [editModal, setEditModal] = useState(false);

    const { t } = useTranslation()

    const [loading, setLoading] = useState(false);

    const user = useSelector(state => state.auth.data.backOfficeUserDTO)

    const hasReadPermission = (permissionType) => {
        const module = user?.permissions?.find(perm => perm.moduleCode === "KYB_DASHBOARD");
        return module ? JSON.parse(module.permissions)[permissionType] : false;
    };

    const [columns, setColumns] = useState([
        { title: t("FIRST_NAME"), field: 'firstName',width: 200},
        { title:  t("LAST_NAME"), field: 'lastName',width: 200},
        { title: t("EMAIL"), field: 'businessEmail',width: 200},
        { title: t("COUNTRY"), field: 'country',width: 200},
        { title: t("STATUS"), field: 'kycSubmitStatus',width: 200,
            render: rowData => {
                const badgeColour = rowData?.kycSubmitStatus?.toLowerCase() === "active" ? "bg-active" :
                    rowData?.kycSubmitStatus?.toLowerCase() === "pending" ? "bg-in-review" :
                    rowData?.kycSubmitStatus?.toLowerCase() === "submitted" ? "bg-new" :
                    rowData?.kycSubmitStatus?.toLowerCase() === "failed" ? "bg-danger" : "bg-ready-for-dispatch";
                return <Badge className={`py-1 ${badgeColour}`}>{rowData.kycSubmitStatus}</Badge>;
            },
        },
    ]);

    const [isTableData,setTableData] = useState([])

    const navigate = useNavigate();

    useEffect(()=>{
        fetchKYBDashboardUsers()
    },[])


    const fetchKYBDashboardUsers = async () =>{
        setLoading(true)
        try {
            const response = await GetAllKYCUsers()
            setTableData(response.result)
            setLoading(false)
        } catch (error) {
            toast.error("Error: Fetch KYB Users")
            setLoading(false)
        }
    }


    // table view button action event
    const handleRowEdit = (rowData) => {
        setClickedRowData(rowData)
        toggleTransactionHistoryModal();
    };

    const handleRowView = async (rowData) => {
        //setIsNew(false)
        setSelectedRowData(rowData)
        setKycModel(true)
    }

    const editAuthorizeEmail = async (rowData) => {
        console.log(rowData)
        setSelectedRowData(rowData)
        setEditModal(true)
    }

    const editModalClose = () => {
        setEditModal(false)
        setSelectedRowData(null)
    }

    const validationSchemaAuthorizeEmail = yup.object().shape({
        authorizeEmail: yup
            .string()
            .email(t("EMAIL_IS_REQUIRED"))
            .required(t("EMAIL_IS_REQUIRED")),
    });

    const onSubmitAuthorizeEmail = async (values) => {
        setLoading(true)
        try {
            const data = {
                coUserId: values.coUserId,
                authorizeEmail: values.authorizeEmail,
                isAuthorized: true,
            };
            console.log("data", data)
            const response = await updateUserDetails(data);
            setEditModal(false)
            await fetchKYBDashboardUsers();
            toast.success(t("SUCCESSFULLY_UPDATED_USER_DETAILS"));

        } catch (error) {
            setEditModal(false);
            toast.error(t("ERROR_UPDATE_USER_DETAILS"));
        }
        setLoading(false)
    }

    document.title = `${t("KYB_DASHBOARD")} | ${t("TPS_BACKOFFICE")}`;
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="page-title-box p-0">
                        <Row>
                            <Col md={6}>
                                <Breadcrumbs title={t("TPS_BACKOFFICE")} breadcrumbItem={t("KYB_DASHBOARD")} mainTitleLink="/" titleLink="#" />
                            </Col>
                        </Row>
                    </div>

                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody className="custom-search-table-card">
                                    <CardTitle className="h4 custom-search-table-title">{t("KYB_USERS")}</CardTitle>

                                    {/*<MDBDataTable responsive striped bordered data={data} />*/}
                                    <div className="custom-table custom-search-table">
                                        <MaterialTableCustom
                                            data={hasReadPermission("VIEW") ? isTableData : []}
                                            columns={columns}
                                            loading={loading}
                                            isSearch={true}
                                            // isDownloadDocuments={true}
                                            tableRowViewHandler={hasReadPermission("VIEW") ? handleRowView : null}
                                            customAction={
                                                hasReadPermission("UPDATE") ?
                                                    {
                                                    icon: () => <EditIcon style={{ color: '#757575FF' }} />,
                                                    tooltip: 'Edit Authorize Email',
                                                    disabled: false,
                                                    onClick: (event, selectedRow) => editAuthorizeEmail(selectedRow)
                                                } : null
                                            }
                                        />
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {kycModel && (
                        <KycModel rowData={selectedRowData}  isOpen={true} toggleModal={() => {
                            setKycModel(false)
                            setSelectedRowData(null)
                        }}/>
                    )}

                </Container>
            </div>

            {
                editModal && (

                    <Modal isOpen={editModal} centered={true} size="lg">
                        <Formik
                            enableReinitialize
                            initialValues={{
                                coUserId: selectedRowData?.coUserId ? selectedRowData?.coUserId : "",
                                authorizeEmail: selectedRowData?.authorizeEmail ? selectedRowData?.authorizeEmail : "",
                            }}
                            validationSchema={validationSchemaAuthorizeEmail}
                            onSubmit={onSubmitAuthorizeEmail}
                        >
                            {({ isSubmitting, submitForm }) => (
                                <Form>
                                    <ModalHeader style={{backgroundColor: 'rgb(3, 35, 112)', color: 'white' }}>
                                        {t("UPDATE_AUTHORIZATION_EMAIL")}
                                    </ModalHeader>
                                    <ModalBody>
                                            <Col md={6} xl={6}>
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="email">{t("AUTHORIZATION_EMAIL")}
                                                        <sup className="text-danger"> *</sup>
                                                    </Label>
                                                    <FormTextField
                                                        name="authorizeEmail"
                                                        type="email"
                                                        id="authorizeEmail"
                                                        placeholder={t("AUTHORIZATION_EMAIL")}
                                                    />
                                                </FormGroup>
                                            </Col>
                                    </ModalBody>
                                    <ModalFooter>
                                            <Button color="secondary"  disabled={loading} onClick={editModalClose}>
                                                { loading ? (
                                                    <Spinner size="sm">
                                                        Loading...
                                                    </Spinner>
                                                ) : "" }
                                                {' '}{' '}
                                                {t("CLOSE")}
                                            </Button>
                                            <Button disabled={loading} color="secondary" type="submit">
                                                { loading ? (
                                                    <Spinner size="sm">
                                                        Loading...
                                                    </Spinner>
                                                ) : "" }
                                                {' '}{' '}
                                                {t("UPDATE")}
                                            </Button>
                                    </ModalFooter>
                                </Form>
                            )}
                        </Formik>
                    </Modal>

                    // <Modal isOpen={editModal} centered={true} toggle={editModalClose}>
                    //
                    //     <ModalHeader style={{backgroundColor: 'rgb(3, 35, 112)', color: 'white' }} toggle={null}>
                    //                 {t("AUTHORIZATION_EMAIL")}
                    //     </ModalHeader>
                    //
                    //     <Formik
                    //         enableReinitialize
                    //         initialValues={{
                    //             coUserId: selectedRowData?.coUserId ? selectedRowData?.coUserId : "",
                    //             authorizeEmail: selectedRowData?.authorizeEmail ? selectedRowData?.authorizeEmail : "",
                    //         }}
                    //         validationSchema={validationSchemaAuthorizeEmail}
                    //         onSubmit={onSubmitAuthorizeEmail}
                    //     >
                    //         {({ isSubmitting }) => (
                    //             <Form>
                    //                 <Row>
                    //                     <Col md={6} xl={6}>
                    //                         <FormGroup className="mb-3">
                    //                             <Label htmlFor="authorizeEmail">{t("EMAIL")}
                    //                                 <sup className="text-danger"> *</sup>
                    //                             </Label>
                    //                             <FormTextField
                    //                                 name="authorizeEmail"
                    //                                 type="email"
                    //                                 id="authorizeEmail"
                    //                                 placeholder={t("EMAIL")}
                    //                             />
                    //                         </FormGroup>
                    //                     </Col>
                    //                 </Row>
                    //             </Form>
                    //         )}
                    //     </Formik>
                    //
                    //
                    //     <ModalFooter>
                    //         <Button color="secondary" onClick={editModalClose}>
                    //             {t("CLOSE")}
                    //         </Button>
                    //         <Button color="secondary" onClick={updateAuthorizeEmail}>
                    //             {t("UPDATE")}
                    //         </Button>
                    //     </ModalFooter>
                    // </Modal>
                )
            }

        </React.Fragment>
    );
};

export default KYB_Submit;
